import React from "react";
import Swal from "sweetalert2";
import _service from '../../service/service';
import ModalAssistant from './modal.assistant';
import ModalResend from './modal-resend-whatapps.assistant';
import ModalBadge from './modal-badge-history.assistant';
import ModalPhoto from './modal-photo.assistant';
import auth from '../../service/auth';

export default class Assistant extends React.Component {



    constructor(props) {
        super(props);
        this.state = {
            firstEnter: true,
            pagination: {
                page: 0,
                size: 50,
                value: ''
            },
            totalPages: 0,
            totalRegister: 0,
            assistants: [],
            loadingSearch: false,
            viewRegister: false,
            loadingViewError: false,
            basehref: "javascript:void(0)",
            fields: [],
            showModalAssistant: false,
            assistantSelected: null,
            showModalResend: false,
            showModalBadge: false,
            showModalPhoto: false
        }
        this.getFields();
    }

    getValidationDocument(documents) {
        return new Promise((resolve, reject) => {
            _service.getdocuments(documents)
                .then(resp => {
                    return resolve(resp);
                })
                .catch(erro => {
                    reject(Error)
                })

        })
    }


    photoTake = async (assistant, index) => {
        this.setState({
            showModalPhoto: true,
            assistantSelected: assistant
        });
    }


    badgeHistory = async (assistant, index) => {
        this.setState({
            showModalBadge: true,
            assistantSelected: assistant
        });
    }

    reSendWhatapps = async (assistant, index) => {
        let asssitants = this.state.assistants;
        assistant.sending = true;
        asssitants[index] = assistant;
        this.setState({
            asssitants: asssitants
        });
        _service.getInformationParticipante(assistant.id)
            .then(resp => {
                assistant.sending = false;
                resp.photo = assistant.photo;
                asssitants[index] = assistant;
                this.setState({
                    showModalResend: true,
                    assistantSelected: resp,
                    asssitants: asssitants
                })
            }, error => {
                assistant.sending = false;
                asssitants[index] = assistant;
                this.setState({
                    asssitants: asssitants
                })
            });
    }

    getViewParticpant = async (assistant, index) => {



        Swal.fire({
            html: 'Verificando...',
            showConfirmButton: false,
            showCancelButton: false,
            showLoaderOnConfirm: false,
            focusCancel: false,
            backdrop: false,
            allowEscapeKey: false,
            allowOutsideClick: false
        });

        let documents = await this.getValidationDocument(assistant.documentnumber);
        Swal.close();
        if (documents.length > 0) {
            let documentsSend = documents.map(f => f.document);
            let message = "Los siguientes documetos  " + documentsSend.toString() + " No puede ser impreso ya que se encuentra en lista negra";
            Swal.fire('Información', message, 'info');
            return;
        }

        // if (!assistant.terms_and_conditions) {
        //     let message = "El numero documeto  " + assistant.documentnumber + " No puede ser impreso ya que no aceptado los terminos y condiciones";
        //     // Swal.fire('Información', message, 'info');
        //     let url = _service.getIpServer() + "/public/QRtext?qrtext=";
        //     Swal.fire({
        //         icon: 'info',
        //         title: 'Información',
        //         html: `<div>El asistente no ha aceptado términos y condiciones </div> <div> <img src="${url}https://ev.aplicacionesincontacto.com//%23/terms/${assistant.id}"> </div>`
        //     })
        //     // return;
        // }



        let asssitants = this.state.assistants;
        assistant.loadingView = true;
        asssitants[index] = assistant;
        this.setState({
            asssitants: asssitants
        });
        _service.getInformationParticipante(assistant.id)
            .then(resp => {
                assistant.loadingView = false;
                asssitants[index] = assistant;

                resp.photo = assistant.photo;

                if (assistant.terms_and_conditions) {
                    resp.terms_and_conditions = assistant.terms_and_conditions;
                    resp.terms_and_conditions_date = assistant.terms_and_conditions_date;
                    resp.terms_and_conditions_ip = assistant.terms_and_conditions_ip;
                }

                if (assistant.badge_virtual) {
                    resp.badge_virtual = assistant.badge_virtual;
                    resp.badge_virtual_date = assistant.badge_virtual_date;
                }

                console.log(resp, asssitants);
                this.setState({
                    showModalAssistant: true,
                    assistantSelected: resp,
                    asssitants: asssitants
                })
            }, error => {
                assistant.loadingView = false;
                asssitants[index] = assistant;
                this.setState({
                    asssitants: asssitants
                })
            });
    }


    getFields = () => {
        _service.getFields()
            .then(resp => {
                console.log(resp);
                let fields = resp.filter(fiels => fiels.value !== 'consignacion' && fiels.value !== 'referencia');
                this.setState({
                    fields
                })
            }).catch(error => {

            })
    }

    findAssistant = (page) => {
        if (!this.state.pagination.value) {
            Swal.fire('Información', 'Debe ingresar un valor', 'info');
            return;
        }
        let pagination = this.state.pagination;
        pagination.page = page;
        this.setState({
            firstEnter: false,
            viewRegister: false,
            loadingSearch: true,
            pagination: pagination,
            loadingViewError: false,
        });

        _service.getAssisantByEventPagination(this.state.pagination, this.state.fields)
            .then((resp) => {
                console.log(resp);

                let assistants = resp.assistants;
                assistants.forEach(as => {
                    if (as.terms_and_conditions_date) {
                        as.terms_and_conditions_date = new Date(as.terms_and_conditions_date);
                    }

                    if (as.badge_virtual_date) {
                        as.badge_virtual_date = new Date(as.badge_virtual_date);
                    }

                })

                this.setState({
                    totalPages: resp.totalPages,
                    totalRegister: resp.totalElements,
                    assistants: assistants,
                });
                if (assistants.length <= 0) {
                    this.setState({
                        viewRegister: true
                    });
                }
                this.setState({
                    loadingSearch: false,
                    loadingViewError: false
                });

            }).catch(error => {
                this.setState({
                    loadingSearch: false,
                    loadingViewError: true
                });
            });
    }



    nextPageChanged = () => {
        this.pageCurrentFunction(1);
    }
    backPageChanged = () => {
        this.pageCurrentFunction(0);
    }



    pageCurrentFunction = (type) => {
        let pagination = this.state.pagination;
        if (type === 1) {
            if ((pagination.page + 1) > 0 && (pagination.page + 1) < (this.state.totalPages))
                pagination.page = pagination.page + 1;
            else
                return;
        } else {
            if ((pagination.page + 1) > 1)
                pagination.page = pagination.page - 1;
            else
                return;
        }
        this.setState({
            pagination
        })
        this.findAssistant(pagination.page);
    }




    onKeyUp = (event) => {
        if (event.charCode === 13) {
            this.findAssistant(0);
        }
    }


    changeValueState = (e) => {
        let value = e.target.value;
        let pagination = this.state.pagination;
        if (value) {

            pagination.value = value;
            this.setState({
                pagination
            });
        } else {
            pagination.value = "";
            this.setState({
                pagination
            });
        }
    }


    chageSize = (event) => {
        const { value } = event.target;
        if (!value) {
            let pagination = this.state.pagination;
            pagination.size = 50;
            this.setState({
                pagination
            });
            this.findAssistant(0);
        } else {
            let pagination = this.state.pagination;
            pagination.size = parseInt(value);
            this.setState({
                pagination
            });
            this.findAssistant(0);
        }
    }


    closeModal = (data) => {
        console.log(data);
        this.setState({
            showModalAssistant: data
        });
    }

    closeModalResent = (data) => {
        console.log(data);
        this.setState({
            showModalResend: data
        });
    }

    closeModalBadge = (data) => {
        console.log(data);
        this.setState({
            showModalBadge: data
        });
    }


    closeModalPhoto = (data) => {
        console.log(data);
        this.setState({
            showModalPhoto: data
        });
    }



    render() {
        return (
            <div className="row">

                {this.state.showModalBadge && this.state.assistantSelected
                    ?
                    <ModalBadge closeModal={(data) => this.closeModalBadge(data)} show={this.state.showModalBadge} assistant={this.state.assistantSelected}></ModalBadge>
                    :
                    <div></div>}


{this.state.showModalPhoto && this.state.assistantSelected
                    ?
                    <ModalPhoto closeModal={(data) => this.closeModalPhoto(data)} show={this.state.showModalPhoto} assistant={this.state.assistantSelected}></ModalPhoto>
                    :
                    <div></div>}



                {this.state.showModalResend && this.state.assistantSelected
                    ?
                    <ModalResend closeModal={(data) => this.closeModalResent(data)} show={this.state.showModalResend} assistant={this.state.assistantSelected}></ModalResend>
                    :
                    <div></div>}


                {this.state.showModalAssistant && this.state.assistantSelected
                    ?
                    <ModalAssistant closeModal={(data) => this.closeModal(data)} show={this.state.showModalAssistant} assistant={this.state.assistantSelected}></ModalAssistant>
                    :
                    <div></div>}

                <div className="col-md-6 offset-md-3 col-xs-12 mt-3 mb-2">
                    <div className="input-group fieldSearch">
                        <div className="input-group-prepend filter-new-filter" >
                            <a className="nav-link waves-effect waves-dark" href={this.state.basehref} onClick={() => this.findAssistant(0)} >
                                <i className="fa-solid fa-filter" style={{ color: "#929292" }}></i>
                            </a>
                        </div>
                        <input type="text" className="form-control"
                            onKeyPress={this.onKeyUp}
                            onChange={this.changeValueState}
                            value={this.state.pagination.value}
                            aria-label="Campo de búsqueda"
                            placeholder="Ingrese el valor a buscar" />
                    </div>
                </div>




                {
                    this.state.loadingSearch ?
                        (<div className="col-md-6 offset-md-3">
                            <div className="alert alert-primary text-center">
                                Buscando eventos con el valor ({this.state.pagination.value})...
                            </div>
                        </div>)
                        : (<></>)
                }




                {
                    !this.state.firstEnter && !this.state.loadingSearch && this.state.viewRegister && !this.state.loadingViewError
                        ?
                        (<div className="col-md-6 offset-md-3">
                            <div className="card card-body card-info" style={{ justifyContent: 'center' }}>

                                <div className="row">
                                    <div className="col-md-2 center-component">
                                        <h4>
                                            <i className="fa-solid fa-star text-card-info"></i>
                                        </h4>

                                    </div>

                                    <div className="col-md-10  center-component" style={{ justifyContent: 'initial' }}>
                                        <div>
                                            <div className="text-left ">
                                                <b>
                                                    Información
                                                </b>
                                            </div>
                                            <div className="text-left description">
                                                No se encontró ningún registro
                                            </div>

                                        </div>

                                    </div>
                                </div>


                            </div>


                        </div>
                        ) : (<></>)

                }







                {
                    this.state.firstEnter && !this.state.loadingSearch && !this.state.viewRegister && !this.stateloadingViewError ? (
                        <div className="col-md-6 offset-md-3">
                            <div className="card card-body card-success" style={{ justifyContent: 'center' }}>

                                <div className="row">
                                    <div className="col-md-2 center-component">
                                        <h4>
                                            <i className="fa-solid fa-star text-card-success"></i>
                                        </h4>

                                    </div>

                                    <div className="col-md-10  center-component" style={{ justifyContent: 'initial' }}>
                                        <div>
                                            <div className="text-left ">
                                                <b>
                                                    Información
                                                </b>
                                            </div>
                                            <div className="text-left description">
                                                Ingrese un valor para realizar la búsqueda.
                                            </div>

                                        </div>

                                    </div>
                                </div>


                            </div>


                        </div>

                    )
                        : (<></>)
                }



                {
                    this.state.loadingViewError
                        ?

                        (<div className="col-md-6 offset-md-3">
                            <div className="card card-body card-danger" style={{ justifyContent: 'center' }}>

                                <div className="row">
                                    <div className="col-md-2 center-component">
                                        <h4>
                                            <i className="fa-solid fa-star text-card-danger"></i>
                                        </h4>

                                    </div>

                                    <div className="col-md-10  center-component" style={{ justifyContent: 'initial' }}>
                                        <div>
                                            <div className="text-left ">
                                                <b>
                                                    Información
                                                </b>
                                            </div>
                                            <div className="text-left description">
                                                Ocurrio un error inesperado realizando la consulta al servidor
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>)

                        : (<></>)
                }


                {
                    !this.state.firstEnter && !this.state.loadingSearch && !this.state.viewRegister && !this.state.loadingViewError
                        ?
                        (<div className="col-md-12">
                            <div className="row">

                                <div className="col-md-12 mt-3">
                                    <h5 className="text-card-info text-left">
                                        Asistentes: {this.state.totalRegister}     asistentes
                                    </h5>
                                    <div className="hr-personlization"></div>
                                </div>

                                {
                                    this.state.assistants.map((assistant, index) => {
                                        return (<div className={`card card-body card-assistant 
                                            ${assistant.status === 'ACEPTADO' ? 'border-left-asssitant-success' : (assistant.change ? 'border-left-asssitant-warning' : 'border-left-asssitant-danger')}
                                        `}

                                            key={index}>
                                            <div className="title-name">
                                                {assistant.name || '-'} {assistant.lastName || '-'}
                                            </div>
                                            <div className="title-coimpany">
                                                {assistant.company || '-'}
                                            </div>
                                            <div className="text-left fieldsAssistant">
                                                <b> Documento: </b> <span>{assistant.documentnumber || '-'}</span>
                                            </div>
                                            <div className="text-left fieldsAssistant">
                                                <b>Categoria: </b>  <span>{assistant.category || '-'}</span>
                                            </div>
                                            <div className="text-left fieldsAssistant">
                                                <b>Inscrito por:</b>   <span>{assistant.inscriptionByName || '-'}</span>
                                            </div>


                                            <div className="text-left fieldsAssistant">
                                                <b>Foto :</b>   <span>{assistant.photo ? 'Si' : 'No'}</span>
                                            </div>

                                            <div className="text-center">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <button className="btn btn-primary btn-sm btn-block" onClick={() => this.photoTake(assistant, index)}>
                                                            Ver informacion de la foto
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="text-left fieldsAssistant">
                                                <b>Escarapela virutal:</b>   <span>{assistant.badge_virtual ? 'Si' : 'No'}</span>

                                            </div>
                                            <div className="text-center">
                                                {
                                                    assistant.badge_virtual &&
                                                    <div className="row">
                                                        <div className="col-md-6 offset-md-3">
                                                            <button className="btn btn-primary btn-sm" onClick={() => this.badgeHistory(assistant, index)}>
                                                                + Ver historial de escarapela virtual
                                                            </button>
                                                        </div>
                                                    </div>

                                                }
                                            </div>

                                            {
                                                assistant.badge_virtual
                                                &&
                                                <div className="text-left fieldsAssistant">
                                                    <b>Fecha Escarapela virtual:</b>   <span>{assistant.badge_virtual_date.getDate() + "-" + (assistant.badge_virtual_date.getMonth() + 1) + "-" + assistant.badge_virtual_date.getFullYear()}</span>
                                                </div>
                                            }


                                            <div className="text-left fieldsAssistant">
                                                <b>Terminos y condiciones:</b>   <span>{assistant.terms_and_conditions ? 'Si' : 'No'}</span>
                                            </div>


                                            {
                                                assistant.terms_and_conditions
                                                &&
                                                <div className="text-left fieldsAssistant">
                                                    <b>Terminos y condiciones (Fecha):</b>   <span>{assistant.terms_and_conditions_date.getDate() + "-" + (assistant.terms_and_conditions_date.getMonth() + 1) + "-" + assistant.terms_and_conditions_date.getFullYear()}</span>
                                                </div>

                                            }

                                            {
                                                assistant.terms_and_conditions
                                                &&
                                                <div className="text-left fieldsAssistant">
                                                    <b>Terminos y condiciones (Ip):</b>   <span>{assistant.terms_and_conditions_ip}</span>
                                                </div>

                                            }


                                            {
                                                assistant.status !== 'ACEPTADO'
                                                    ?
                                                    <div className="text-left fieldsAssistant danger-text-status" >
                                                        <b>   El prarticipante tiene el pago en:</b>  <span>{assistant.status || '-'}</span>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }

                                            {
                                                assistant.change
                                                &&
                                                <div className="text-left fieldsAssistant danger-text-status" >
                                                    El participante no se puede imprimir ya que tiene un cambio
                                                </div>

                                            }

                                            {
                                                assistant.status === 'ACEPTADO' && !assistant.change

                                                    ?
                                                    <div className="row">
                                                        <div className="col-md-2 offset-md-5">

                                                            {
                                                                assistant.loadingView ?
                                                                    <div className="text-center font-size-14">
                                                                        <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
                                                                    </div>
                                                                    :
                                                                    <button className="btn btn-primary btn-sm" onClick={() => this.getViewParticpant(assistant, index)}>
                                                                        <i className="fa fa-print" aria-hidden="true"></i>
                                                                    </button>
                                                            }
                                                        </div>
                                                    </div>
                                                    :
                                                    <div></div>


                                            }



                                            {
                                                assistant.status === 'ACEPTADO' && !assistant.change ?
                                                    <div className="row mt-3">
                                                        <div className="col-md-2 offset-md-5">

                                                            {
                                                                assistant.sending ?
                                                                    <div className="text-center font-size-14">
                                                                        <i className="fa fa-circle-o-notch fa-spin" aria-hidden="true"></i>
                                                                    </div>
                                                                    :
                                                                    <button className="btn btn-primary btn-sm" onClick={() => this.reSendWhatapps(assistant, index)}>
                                                                        <i className="fa fa-commenting" aria-hidden="true"></i>
                                                                    </button>
                                                            }
                                                        </div>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }



                                        </div>)
                                    })
                                }




                                <div className="col-md-12 mt-3">
                                    <div className="card border-none">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="  col-xs-6 col-md-6 col-sm-6">
                                                    <div className="center-component">
                                                        <div className="text-center">
                                                            <select
                                                                className="form-control form-control-sm text-primary font-weight-bold mr-4 border-0 bg-light-primary"
                                                                onChange={this.chageSize.bind(this)}
                                                                value={this.state.pagination.size} style={{ width: '75px' }}>
                                                                <option value="10">10</option>
                                                                <option value="20">20</option>
                                                                <option value="50">50</option>
                                                                <option value="60">60</option>
                                                                <option value="100">100</option>
                                                                <option value="200">200</option>
                                                                <option value="300" > 300</option>
                                                            </select>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div className=" col-xs-6 col-md-6 col-sm-6 ">
                                                    <div className="text-center">
                                                        <a
                                                            href={this.state.basehref}
                                                            onClick={this.backPageChanged.bind(this)}
                                                            className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
                                                            <i className="fas fa-backward text-muted"></i>
                                                        </a>
                                                        <a
                                                            href={this.state.basehref}
                                                            className="btn btn-icon btn-sm border-0 btn-hover-primary active mr-2 my-1 text-muted">
                                                            {(this.state.pagination.page + 1)}</a>

                                                        <a
                                                            href={this.state.basehref}
                                                            onClick={this.nextPageChanged.bind(this)}
                                                            className="btn btn-icon btn-sm btn-light-primary mr-2 my-1">
                                                            <i className="fas fa-forward text-muted"></i>
                                                        </a>
                                                    </div >
                                                </div >

                                                <div className="col-xs-12 col-md-12 col-sm-12    text-center">
                                                    <span className="text-muted"> Mostrando &nbsp; {this.state.pagination.size}&nbsp;
                                                        de&nbsp; {this.state.totalRegister}&nbsp;
                                                        registros </span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)
                        : (<></>)
                }


            </div >
        )
    }

}