import React, { Component } from "react";
import _service from '../../service/service';

export default class ViewAssistant extends Component {


    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    render() {
        return (
            <div className="row">
                <div className="col-md-12">

                    <div className="row">
                        <div className="col-md-6 center-component-mobil">
                            <b>
                                Documento:
                            </b>

                        </div>
                        <div className="col-md-6 center-component-mobil">
                            {this.props.assistant.documentnumber}
                        </div>

                    </div>


                    <div className="row">
                        <div className="col-md-6 center-component-mobil">
                            <b>
                                Nombre:
                            </b>
                        </div>
                        <div className="col-md-6 center-component-mobil">
                            {this.props.assistant.name}          {this.props.assistant.lastName}
                        </div>

                    </div>


                    <div className="row">
                        <div className="col-md-6 center-component-mobil">
                            <b>
                                Empresa:
                            </b>
                        </div>
                        <div className="col-md-6 center-component-mobil">
                            {this.props.assistant.company}
                        </div>

                    </div>




                    <div className="row">
                        <div className="col-md-6 center-component-mobil">
                            <b>
                                Pais:
                            </b>
                        </div>
                        <div className="col-md-6 center-component-mobil">
                            {this.props.assistant.countryname}
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-md-6 center-component-mobil">
                            <b>
                                Departamento:
                            </b>
                        </div>
                        <div className="col-md-6 center-component-mobil">
                            {this.props.assistant.department}
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-md-6 center-component-mobil">
                            <b>
                                Ciudad:
                            </b>
                        </div>
                        <div className="col-md-6 center-component-mobil">
                            {this.props.assistant.cityname}
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-md-6 center-component-mobil">
                            <b>
                                Categoria:
                            </b>
                        </div>
                        <div className="col-md-6 center-component-mobil">
                            {this.props.assistant.category.name}
                        </div>

                    </div>



                </div>

            </div>
        )
    }

}