import Swal from 'sweetalert2';
import _auth from './auth';

class Service {
    // url = "http://localhost:8888";
    // url = "https://aplicacionesincontacto.com:48080/inexmodapitest";
    FAIR_ID = '17';

    getIpEspejo = () => {
        let ipEspejo = localStorage.getItem('IpEspejo');
        return ipEspejo;
    }

    getIpServer = () => {
        let ipserver = localStorage.getItem('ipserver');
        if (!ipserver) {
            // ipserver = 'https://feria.aplicacionesincontacto.com/inexmodapi';
            // ipserver = "https://aplicacionesincontacto.com:48080/inexmodapiheim"
            ipserver = "https://reportes.aplicacionesincontacto.com/inexmodapi"
            // ipserver = "http://localhost:8888"
        }
        return ipserver;
    }

    getPrinter = () => {
        let printer = localStorage.getItem('Printer');
        return printer;
    }

    imprimir = (parameter) => {
        let data = {};
        let ipEspejo = this.getIpEspejo();
        data.type = 1;
        data.print = this.getPrinter();
        data.eventId = 'inexmoda';
        data.fields = ["nombre", "apellido", "empresa", "pais", "ciudad", "categoria", 'codebar', 'cedula', 'email', "id", "tel2", "foto", "nombrecomercial"];
        data.assistants = [parameter];
        data.print_by_table = true;

        //         assistants: [{627d131507d5f30018b3ace2: "GENERAL", ID: "62dad40e80e68445c534dfd0", NOMBRE: "EDILBERTO",…}]
        // eventId: "62dad3ee80e68445c53456a8"
        // fields: ["nombre", "apellido", "empresa", "pais", "ciudad","categoria",'codebar','cedula','email']
        // print: "No selected"
        // print_by_table: false
        // type: 1
        // urlPrint: "https://aplicacionesincontacto.com:48080/print/print/printing"


        return new Promise(async (resolve, reject) => {

            fetch(`${ipEspejo}/print/print/printing/server`, {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                headers: await this.getHeaders(false, "application/json"),
                body: JSON.stringify(data),
            })
                .then(await this.reponseFetch)
                .then(async (response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    this.verifyServerError(error, reject);
                });



            // fetch(`${ipEspejo}/jsonfice/json`, {
            //     method: "POST", // *GET, POST, PUT, DELETE, etc.
            //     headers: await this.getHeaders(false, "application/json; charset=UTF-8"),
            //     body: JSON.stringify(parameter),
            // })
            //     .then(await this.reponseFetch)
            //     .then(async (response) => {
            //         resolve(response);
            //     })
            //     .catch((error) => {
            //         console.log(error);
            //         this.verifyServerError(error, reject);
            //     });

        })


    }



    getPrinters = () => {
        let ipEspejo = this.getIpEspejo();
        return new Promise(async (resolve, reject) => {
            fetch(`${ipEspejo}/print/print/all`, {
                method: "GET", // *GET, POST, PUT, DELETE, etc.
                headers: await this.getHeaders(false, "application/json"),
            })
                .then(await this.reponseFetch)
                .then(async (response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    this.verifyServerError(error, reject);
                });
        })


        // return new Promise(async (resolve, reject) => {
        //     fetch(`${ipEspejo}/jsonfice/json`, {
        //         method: "POST", // *GET, POST, PUT, DELETE, etc.
        //         headers: await this.getHeaders(false, "application/json; charset=UTF-8"),
        //         body: JSON.stringify(parameter),
        //     })
        //         .then(await this.reponseFetch)
        //         .then(async (response) => {
        //             resolve(response);
        //         })
        //         .catch((error) => {
        //             console.log(error);
        //             this.verifyServerError(error, reject);
        //         });
        // })


    }



    findAssistantByEventUser = async (documentNumber) => {
        return new Promise(async (resolve, reject) => {
            let url = this.getIpServer();
            fetch(`${url}/api/assistantapp/event/${this.FAIR_ID}/assistant/${documentNumber}`, {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                headers: await this.getHeaders(true, "application/json"),
                body: JSON.stringify({}),
            })
                .then(await this.reponseFetch)
                .then(async (response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    this.verifyServerError(error, reject);
                });
        })
    }



    findAssistantDocuement = async (documentNumber) => {
        return new Promise(async (resolve, reject) => {
            let url = this.getIpServer();
            fetch(`${url}/api/assistant/event/${this.FAIR_ID}/document/${documentNumber}`, {
                method: "GET", // *GET, POST, PUT, DELETE, etc.
                headers: await this.getHeaders(true, "application/json"),
            })
                .then(await this.reponseFetch)
                .then(async (response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    this.verifyServerError(error, reject);
                });
        })
    }




    printAssitants = async (listPrint) => {
        listPrint.forEach(element => {
            element.events = null;
            delete element.events;
        });
        return new Promise(async (resolve, reject) => {
            let url = this.getIpServer();
            fetch(`${url}/api/assistants/printAssistantsapp/${this.FAIR_ID}`, {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                headers: await this.getHeaders(true, "application/json"),
                body: JSON.stringify(listPrint),
            })
                .then(await this.reponseFetch)
                .then(async (response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    this.verifyServerError(error, reject);
                });
        })
    }


    getCategories = async () => {
        return new Promise(async (resolve, reject) => {
            let url = this.getIpServer();
            fetch(`${url}/api/assistants/getCategoriesByView?view=tablet`, {
                method: "GET", // *GET, POST, PUT, DELETE, etc.
                headers: await this.getHeaders(true, "application/json"),
            })
                .then(await this.reponseFetch)
                .then(async (response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    this.verifyServerError(error, reject);
                });
        })
    }



    updateAssistnat = async (categoryId, assistant) => {
        assistant.events = null;
        delete assistant.events;
        return new Promise(async (resolve, reject) => {
            let url = this.getIpServer();
            fetch(`${url}/api/assistantbyapp/event/${this.FAIR_ID}/category/${categoryId}`, {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                headers: await this.getHeaders(true, "application/json"),
                body: JSON.stringify(assistant),
            })
                .then(await this.reponseFetch)
                .then(async (response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    this.verifyServerError(error, reject);
                });
        })
    }




    getInformationParticipante = async (idParticipant) => {
        return new Promise(async (resolve, reject) => {
            let url = this.getIpServer();
            fetch(`${url}/api/assistant/event/${this.FAIR_ID}/assistant/${idParticipant}`, {
                method: "GET", // *GET, POST, PUT, DELETE, etc.
                headers: await this.getHeaders(true, "application/json"),
            })
                .then(await this.reponseFetch)
                .then(async (response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    this.verifyServerError(error, reject);
                });
        })
    }



    login = async (email, password) => {
        return new Promise(async (resolve, reject) => {
            let url = this.getIpServer();
            fetch(`${url}/api/login`, {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                headers: await this.getHeaders(false, "application/json"),
                body: JSON.stringify({
                    username: email, password
                }),
            })
                .then(await this.reponseFetch)
                .then(async (response) => {
                    let token = response.headers.get('X-INEXMODA-TOKEN');
                    let user = await response.json();
                    let data = {
                        user,
                        token
                    }
                    resolve(data);
                })
                .catch((error) => {
                    console.log(error);
                    this.verifyServerError(error, reject);
                });
        })
    }

    getFields = async () => {
        return new Promise(async (resolve, reject) => {
            let url = this.getIpServer();
            fetch(`${url}/api/assistants/getFields`, {
                method: "GET", // *GET, POST, PUT, DELETE, etc.
                headers: await this.getHeaders(true, "application/json"),
            })
                .then(await this.reponseFetch)
                .then(async (response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    this.verifyServerError(error, reject);
                });
        })
    }


    // return this.http.post(url + '/api/assistants/getAssistantsByVisitors/' + eventId + '/' + -1 + '/true/' + searchPrinter + '?size=' + size + '&page=' + currentPage + '&value=' + value, parameters, { headers })


    getAssisantByEventPagination = async (pagination, listFields) => {
        return new Promise(async (resolve, reject) => {
            var parameters = {
                fields: listFields,
                fieldsAdvanced: []
            };
            let url = this.getIpServer();
            fetch(`${url}/api/assistants/getAssistantsByVisitors/${this.FAIR_ID}/-1/true/2?size=${pagination.size}&page=${pagination.page}&value=${pagination.value}`, {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                headers: await this.getHeaders(true, "application/json"),
                body: JSON.stringify(parameters),
            })
                .then(await this.reponseFetch)
                .then(async (response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    this.verifyServerError(error, reject);
                });
        })
    }

    getBrowser = () => {
        var ua = navigator.userAgent, tem,
            M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE ' + (tem[1] || '');
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
        return M.join(' ');
    }


    getSystemOperativo() {
        var OSName = "Desconocido";
        if (navigator.appVersion.indexOf("Win") !== -1) OSName = "Windows";
        if (navigator.appVersion.indexOf("Mac") !== -1) OSName = "MacOS";
        if (navigator.appVersion.indexOf("X11") !== -1) OSName = "UNIX";
        if (navigator.appVersion.indexOf("Linux") !== -1) OSName = "Linux";
        if (navigator.appVersion.indexOf("Android") !== -1) OSName = "Android";

        return OSName;
    }


    getHeaders = async (hasToken, type) => {
        let headers = { 'Content-Type': type };
        if (hasToken) {
            let token = await localStorage.getItem('tokendigitizer');
            headers["X-INEXMODA-TOKEN"] = token;
            return headers;
        } else {
            return headers;
        }
    }


    reponseFetch = async (response) => {
        if (response.ok) {
            if (response.url.indexOf("api/login") >= 0) {
                return response;
            } else {
                return response.json();
            }

        } else {
            if (response.status === 401 || response.status === 403) {
                if (response.url.indexOf("api/login") === -1) {
                    await _auth.logaunt2();
                    throw await response.json();
                } else {
                    throw await response.json();
                }
            } else {
                throw await response.json();
            }
        }
    }

    verifyServerError = async (error, reject) => {
        let message = "Ocurrio un error inesperado";
        if (error.error) {
            message = error.error;
            if (error.error.message) {
                message = error.error.message;
            }
            if (error.error.error && error.error.error.message) {
                message = error.error.error.message;
            }
        } else if (error.message) {
            if (error.message && error.message.toLowerCase().includes('network request failed')) {
                message = 'Revise la url del servidor porque al parecer está erronea';
            } else {
                message = error.message;
            }
        }
        Swal.fire('', message, 'warning');

        reject(error);

    }


    getdocuments = async (document) => {
        return new Promise(async (resolve, reject) => {
            let url = this.getIpServer();
            fetch(`${url}/api/blacklist/documents`, {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                headers: await this.getHeaders(true, "application/json"),
                body: JSON.stringify([document]),
            })
                .then(await this.reponseFetch)
                .then(async (response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    this.verifyServerError(error, reject);
                });
        })
    }


    getcountries = async () => {
        return new Promise(async (resolve, reject) => {
            let url = this.getIpServer();
            fetch(`${url}/public/assistant/countries`, {
                method: "GET", // *GET, POST, PUT, DELETE, etc.
                headers: await this.getHeaders(true, "application/json")
            })
                .then(await this.reponseFetch)
                .then(async (response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    this.verifyServerError(error, reject);
                });
        })
    }

    saveResentWhatapps = async (assistantId, userId, data) => {
        return new Promise(async (resolve, reject) => {
            let url = this.getIpServer();
            fetch(`${url}/public/whatapps/event/${this.FAIR_ID}/assistant/${assistantId}/user/${userId}/sendterms`, {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                headers: await this.getHeaders(true, "application/json"),
                body: JSON.stringify(data)
            })
                .then(await this.reponseFetch)
                .then(async (response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    this.verifyServerError(error, reject);
                });
        })
    }

    getHistoryBadge = async (assistantId) => {
        return new Promise(async (resolve, reject) => {
            let url = this.getIpServer();
            fetch(`${url}/public/termsconditions/get/all/printvirtual`, {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                headers: await this.getHeaders(true, "application/json"),
                body: JSON.stringify({
                    assistant_id: assistantId
                })
            })
                .then(await this.reponseFetch)
                .then(async (response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    this.verifyServerError(error, reject);
                });
        })
    }

    getUserPermission = () => {
        return new Promise(async (resolve, reject) => {
            let url = this.getIpServer();
            fetch(`${url}/api/user`, {
                method: "GET", // *GET, POST, PUT, DELETE, etc.
                headers: await this.getHeaders(true, "application/json"),
            })
                .then(await this.reponseFetch)
                .then(async (response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    this.verifyServerError(error, reject);
                });
        })
    }

    getZones = () => {
        return new Promise(async (resolve, reject) => {
            let url = this.getIpServer();
            fetch(`${url}/api/assistants/getZones`, {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                headers: await this.getHeaders(true, "application/json"),
            })
                .then(await this.reponseFetch)
                .then(async (response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    this.verifyServerError(error, reject);
                });
        });
    }

    getAccess = (zona, codigobar, isBadge) => {
        return new Promise(async (resolve, reject) => {
            let url = this.getIpServer();
            fetch(`${url}/api/assists/control/${this.FAIR_ID}/${zona}/${codigobar}`, {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                headers: await this.getHeaders(true, "application/json"),
                body: JSON.stringify({
                    isBadge: isBadge
                })
            })
                .then(await this.reponseFetch)
                .then(async (response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    this.verifyServerError(error, reject);
                });
        });
    }


    getInformationPhoto = (assistantId) => {
        return new Promise(async (resolve, reject) => {
            let url = this.getIpServer();
            fetch(`${url}/public/photo/event/${this.FAIR_ID}/get/idassistant`, {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                headers: await this.getHeaders(true, "application/json"),
                body: JSON.stringify({
                    document: assistantId
                })
            })
                .then(await this.reponseFetch)
                .then(async (response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    this.verifyServerError(error, reject);
                });
        });
    }


    savePhoto = (isssistant, base64) => {
        return new Promise(async (resolve, reject) => {
            let url = this.getIpServer();
            fetch(`${url}/public/photo/upload/${isssistant}/base64`, {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                headers: await this.getHeaders(true, "application/json"),
                body: JSON.stringify({
                    name: base64
                })
            })
                .then(await this.reponseFetch)
                .then(async (response) => {
                    resolve(response);
                })
                .catch((error) => {
                    console.log(error);
                    this.verifyServerError(error, reject);
                });
        });
    }



}

export default new Service();