import React, { Component } from "react";
import { Modal, Button, Form } from 'react-bootstrap';
import Swal from "sweetalert2";
import { registerQuotaErrorCallback } from "workbox-core";
import ModalPhoto from './modal-photo.assistant';

import _service from '../../service/service';

import FieldAssistant from './field.assistant';
import ViewAssistant from './view.assistant';

import ModalBadge from './modal-badge-history.assistant';

export default class ModalAssistant extends Component {

    constructor(props) {
        super(props);

        if (this.props.assistant.terms_and_conditions_date) {
            this.props.assistant.terms_and_conditions_date = new Date(this.props.assistant.terms_and_conditions_date);
        }

        if (this.props.assistant.badge_virtual_date) {
            this.props.assistant.badge_virtual_date = new Date(this.props.assistant.badge_virtual_date);
        }

        this.state = {
            categories: [],
            loadingView: true,
            isViewPrint: true,
            motivo: "",
            saving: false,
            showModalBadge: false
        }
    }

    componentDidMount() {

        let listPickerMotivoReempresion = [
            { key: "Fallo en impresora", label: "Fallo en impresora" },
            { key: "Cambio en categoría", label: "Cambio en categoría" },
            { key: "Daño de escarapela", label: "Daño de escarapela" },
            { key: "Pérdida de escarapela", label: "Pérdida de escarapela" },
            { key: "Inconveniente en envío (SERVIENTREGA)", label: "Inconveniente en envío (SERVIENTREGA)" },
            { key: "Cobro por reimpresión", label: "Cobro por reimpresión" },
            { key: "OTRO", label: "OTRO" }];

        this.setState({
            listPickerMotivoReempresion: listPickerMotivoReempresion
        })


        this.getCategories();
    }


    selectedChange = (event) => {
        this.setState({
            motivo: event.target.value
        })
    }

    getCategories = () => {
        this.setState({
            loadingView: false
        })
        _service.getCategories()
            .then(resp => {


                let isViewPrint = true;
                if (this.props.assistant.category)
                    isViewPrint = resp.find(f => f.id === this.props.assistant.category.id) ? true : false;

                this.getRequired();

                this.setState({
                    categories: resp,
                    loadingView: false,
                    isViewPrint: isViewPrint
                });
            })
            .catch(error => {
                this.setState({
                    loadingView: false
                })

            })
    }

    handleClose = ($event) => {
        this.props.closeModal(false);
    }

    print = () => {
        let listAssistent = [];
        listAssistent.push({ id: this.props.assistant.id, documentnumber: this.props.assistant.documentnumber });
        this.printAssitants(listAssistent);
    }

    printMotivo = () => {

        if (!this.state.motivo) {
            Swal.fire('Información', 'Debe seleccionar el motivo de reimpresion para continuar', 'warning')
            return;
        }

        let assistant = JSON.parse(JSON.stringify(this.props.assistant));
        assistant.printobservation = assistant.printobservation + ":" + this.state.motivo;
        delete assistant.category;
        this.setState({ saving: true });
        _service.updateAssistnat(this.props.assistant.category.id, assistant)
            .then(response => {
                let listAssistent = [];
                listAssistent.push({ id: response.id, documentnumber: response.documentnumber });
                this.printAssitants(listAssistent);
                // this.setState({ saving: false });
            })
            .catch(err => this.setState({ saving: false }));

        this.print();
    }

    printAssitants = (listAssistent) => {
        if (this.state.saving) {
            return;
        }
        this.setState({
            messageLoader: " Guardando Impresion...",
            saving: true
        })
        _service.printAssitants(listAssistent)
            .then(response => {
                this.printer(response);

            })
            .catch(err => this.setState({ saving: false }))
    }

    printer = (response) => {

        var parameter = {
            nombre: response.assistants[0].name,
            apellido: response.assistants[0].lastName,
            empresa: response.assistants[0].company,
            pais: response.assistants[0].countryname,
            ciudad: response.assistants[0].cityname,
            categoria: response.assistants[0].category,
            codebar: response.assistants[0].codebar,
            cedula: response.assistants[0].documentnumber,
            email: response.assistants[0].email,
            tel2: response.assistants[0].tel2,
            id: response.assistants[0].codebar,
            foto: response.assistants[0].photo ? 'FL' : '',
            nombrecomercial: (response.assistants[0].tradename || '')
        };
        this.setState({
            messageLoader: "Imprimiendo escarapela..."
        });
        _service.imprimir(parameter)
            .then(response => {
                this.handleClose();
                Swal.fire('Información', 'El registro se imprimio correctamente', 'success');
                if (this.props.assistant && !this.props.assistant.terms_and_conditions) {
                    // let message = "El numero documeto  " + assistant.documentnumber + " No puede ser impreso ya que no aceptado los terminos y condiciones";
                    // Swal.fire('Información', message, 'info');
                    let url = _service.getIpServer() + "/public/QRtext?qrtext="; Swal.fire({
                        icon: 'info',
                        title: 'Información',
                        html: `<div>El asistente no ha aceptado términos y condiciones </div> <div> <img src="${url}https://ev.aplicacionesincontacto.com//%23/terms/${this.props.assistant.id}"> </div>`
                    })
                    // return;
                }
                this.setState({ saving: false });
            })
            .catch(err => this.setState({ saving: false }))
    }

    getRequired = () => {

        let fieldAssistantView = {};
        let view = false;
        if (this.props.assistant) {

            fieldAssistantView.documentnumber = false;
            if (!this.props.assistant.documentnumber) {
                fieldAssistantView.documentnumber = true;
                view = true;
            }


            fieldAssistantView.name = false;
            if (!this.props.assistant.name) {
                fieldAssistantView.name = true;
                view = true;
            }

            fieldAssistantView.lastName = false;
            if (!this.props.assistant.lastName) {
                fieldAssistantView.lastName = true;
                view = true;
            }


            fieldAssistantView.email = false;
            if (!this.props.assistant.email) {
                fieldAssistantView.email = true;
                view = true;
            }


            fieldAssistantView.company = false;
            if (!this.props.assistant.company) {
                fieldAssistantView.company = true;
                view = true;
            }


            fieldAssistantView.countryname = false;
            if (!this.props.assistant.countryname) {
                fieldAssistantView.countryname = true;
                view = true;
            }


            fieldAssistantView.cityname = false;
            if (!this.props.assistant.cityname) {
                fieldAssistantView.cityname = true;
                view = true;
            }


            fieldAssistantView.department = false;
            if (!this.props.assistant.department) {
                fieldAssistantView.department = true;
                view = true;
            }

            fieldAssistantView.category = false;
            if (!this.props.assistant.category) {
                fieldAssistantView.category = true;
                view = true;
            }

            fieldAssistantView.tel2 = false;
            if (!this.props.assistant.tel2) {
                fieldAssistantView.tel2 = true;
                view = true;
            }


            if (this.props.assistant && this.props.assistant.category && (this.props.assistant.category.id === 4 || this.props.assistant.category.id === 5 || this.props.assistant.category.id === 25 || this.props.assistant.category.id === 26)) {

                //Actividad Comercial
                fieldAssistantView.activity = false;
                if (!this.props.assistant.activity) {
                    fieldAssistantView.activity = true;
                    view = true;
                }

                //Feria en la que deseas participar
                fieldAssistantView.type_event = false;
                if (!this.props.assistant.type_event) {
                    fieldAssistantView.type_event = true;
                    view = true;
                }


                //Segmento de mercado de interés
                fieldAssistantView.interestSegment = false;
                if (!this.props.assistant.interestSegment) {
                    fieldAssistantView.interestSegment = true;
                    view = true;
                }

                if (!this.props.assistant.type_event || (this.props.assistant.type_event && (this.props.assistant.type_event === "Colombiatex" || this.props.assistant.type_event === "Ambos"))) {



                    //Otras categorías de interés Colombiatex
                    fieldAssistantView.interest = false;
                    if (!this.props.assistant.interest) {
                        fieldAssistantView.interest = true;
                        view = true;
                    }


                    //Categoría principal de interés Colombiatex
                    fieldAssistantView.current = false;
                    if (!this.props.assistant.current) {
                        fieldAssistantView.current = true;
                        view = true;
                    }


                }

                if (!this.props.assistant.type_event || (this.props.assistant.type_event && (this.props.assistant.type_event === "Colombiamoda" || this.props.assistant.type_event === "Ambos"))) {

                    //Categoría principal de interés Colombiamoda
                    fieldAssistantView.interest_colmoda = false;
                    if (!this.props.assistant.interest_colmoda) {
                        fieldAssistantView.interest_colmoda = true;
                        view = true;
                    }



                    //Otras categorías de interés Colombiamoda
                    fieldAssistantView.other_interest_colmoda = false;
                    if (!this.props.assistant.other_interest_colmoda) {
                        fieldAssistantView.other_interest_colmoda = true;
                        view = true;
                    }

                }
            }

        }


        this.setState({
            view: view,
            fieldAssistantView: fieldAssistantView
        });
    }

    closeModalBadge = (data) => {
        console.log(data);
        this.setState({
            showModalBadge: data
        });
    }

    badgeHistory = async () => {
        this.setState({
            showModalBadge: true,
        });
    }

    photoTake = async () => {
        this.setState({
            showModalPhoto: true
        });
    }



    closeModalPhoto = (data) => {
        console.log(data);
        this.setState({
            showModalPhoto: data
        });
    }


    render() {


        return (
            <Modal show={this.props.show} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    {
                        this.props.assistant ? <Modal.Title>{this.props.assistant.name} {this.props.assistant.lastName} </Modal.Title>
                            : <div></div>
                    }
                </Modal.Header>
                <Modal.Body>

                    {this.state.showModalBadge && this.props.assistant
                        ?
                        <ModalBadge closeModal={(data) => this.closeModalBadge(data)} show={this.state.showModalBadge} assistant={this.props.assistant}></ModalBadge>
                        :
                        <div></div>}


                    {this.state.showModalPhoto && this.props.assistant
                        ?
                        <ModalPhoto closeModal={(data) => this.closeModalPhoto(data)} show={this.state.showModalPhoto} assistant={this.props.assistant}></ModalPhoto>
                        :
                        <div></div>}



                    {
                        this.state.loadingView ?
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <div className="alert alert-primary text-center">
                                        Cargando...
                                    </div>
                                </div>
                            </div>
                            :

                            <div>
                                {
                                    this.state.isViewPrint ?

                                        <div>
                                            {
                                                this.state.view ?
                                                    <FieldAssistant handleClose={this.handleClose} assistant={this.props.assistant} field={this.state.fieldAssistantView}></FieldAssistant>
                                                    :
                                                    <div className="row">

                                                        {

                                                            this.props.assistant.print
                                                                ?

                                                                <div className="col-md-12">

                                                                    <div className="row">

                                                                        <div className="col-md-12 mb-4">
                                                                            <ViewAssistant assistant={this.props.assistant}></ViewAssistant>
                                                                        </div>

                                                                        <div className="col-md-12">

                                                                            <div className="row">
                                                                                <div className="col-md-12 mb-5">


                                                                                    <div className="row">
                                                                                        <div className="col-md-6 center-component-mobil">
                                                                                            <b>
                                                                                                Terminos y condiciones:
                                                                                            </b>

                                                                                        </div>
                                                                                        <div className="col-md-6 center-component-mobil">
                                                                                            {this.props.assistant.terms_and_conditions ? 'Si' : 'No'}
                                                                                        </div>
                                                                                    </div>

                                                                                    {
                                                                                        this.props.assistant.terms_and_conditions &&
                                                                                        <div className="row">
                                                                                            <div className="col-md-6 center-component-mobil">
                                                                                                <b>
                                                                                                    Terminos y condiciones (Fecha):
                                                                                                </b>

                                                                                            </div>
                                                                                            <div className="col-md-6 center-component-mobil">
                                                                                                <span>{this.props.assistant.terms_and_conditions_date.getDate() + "-" + (this.props.assistant.terms_and_conditions_date.getMonth() + 1) + "-" + this.props.assistant.terms_and_conditions_date.getFullYear()}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    }


                                                                                    {
                                                                                        this.props.assistant.terms_and_conditions &&
                                                                                        <div className="row">
                                                                                            <div className="col-md-6 center-component-mobil">
                                                                                                <b>
                                                                                                    Terminos y condiciones (Ip):
                                                                                                </b>

                                                                                            </div>
                                                                                            <div className="col-md-6 center-component-mobil">
                                                                                                {this.props.assistant.terms_and_conditions_ip}                                                                                    </div>
                                                                                        </div>
                                                                                    }
                                                                                </div>

                                                                                <div className="col-md-12 mb-5">
                                                                                    <div className="row">
                                                                                        <div className="col-md-6 center-component-mobil">
                                                                                            <b>
                                                                                                Foto
                                                                                            </b>
                                                                                        </div>
                                                                                        <div className="col-md-6 center-component-mobil">
                                                                                            {this.props.assistant.photo ? 'Si' : 'No'}
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="row">
                                                                                        <div className="col-md-8 offset-md-2">
                                                                                            <button className="btn btn-primary btn-sm" onClick={() => this.photoTake()}>
                                                                                                + Ver informacion de la foto
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                                <div className="col-md-12 mb-5">

                                                                                    <div className="row">
                                                                                        <div className="col-md-6 center-component-mobil">
                                                                                            <b>
                                                                                                Escarapela virutal
                                                                                            </b>

                                                                                        </div>
                                                                                        <div className="col-md-6 center-component-mobil">
                                                                                            {this.props.assistant.badge_virtual ? 'Si' : 'No'}
                                                                                        </div>
                                                                                    </div>


                                                                                    {
                                                                                        this.props.assistant.badge_virtual &&
                                                                                        <div className="row">
                                                                                            <div className="col-md-6 center-component-mobil">
                                                                                                <b>
                                                                                                    Fecha Escarapela virtual
                                                                                                </b>

                                                                                            </div>
                                                                                            <div className="col-md-6 center-component-mobil">
                                                                                                <span>{this.props.assistant.badge_virtual_date.getDate() + "-" + (this.props.assistant.badge_virtual_date.getMonth() + 1) + "-" + this.props.assistant.badge_virtual_date.getFullYear()}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    }


                                                                                    <div className="text-center mt-2">
                                                                                        {
                                                                                            this.props.assistant.badge_virtual &&
                                                                                            <div className="row">
                                                                                                <div className="col-md-8 offset-md-2">
                                                                                                    <button className="btn btn-primary btn-sm" onClick={() => this.badgeHistory()}>
                                                                                                        + Ver historial de escarapela virtual
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>

                                                                                        }
                                                                                    </div>


                                                                                </div>


                                                                            </div>




                                                                            <div className="row">
                                                                                <div className="col-md-6 center-component-mobil">
                                                                                    <b>
                                                                                        Inscrito por:
                                                                                    </b>

                                                                                </div>
                                                                                <div className="col-md-6 center-component-mobil">
                                                                                    {this.props.assistant.inscriptionBy.fullName}
                                                                                </div>

                                                                            </div>




                                                                            <div className="row">
                                                                                <div className="col-md-6 center-component-mobil">
                                                                                    <b>
                                                                                        Contacto que reclama:
                                                                                    </b>

                                                                                </div>
                                                                                <div className="col-md-6 center-component-mobil">
                                                                                    {this.props.assistant.contact}
                                                                                </div>

                                                                            </div>



                                                                            <div className="row">
                                                                                <div className="col-md-6 center-component-mobil">
                                                                                    <b>
                                                                                        Telefono del contacto:
                                                                                    </b>

                                                                                </div>
                                                                                <div className="col-md-6 center-component-mobil">
                                                                                    {this.props.assistant.phonecontact}
                                                                                </div>

                                                                            </div>





                                                                            <div className="row">
                                                                                <div className="col-md-12 mt-3">
                                                                                    <div className="alert alert-danger text-center">
                                                                                        <h6>
                                                                                            La escarapela ya fue impresa
                                                                                        </h6>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-md-12">

                                                                                    <Form.Group className="mb-3 mt-1 control-form     " controlId="motivodeimpresion">
                                                                                        <Form.Label>Seleccione el motivo de reimpresion <span className="text-danger">*</span></Form.Label>
                                                                                        <Form.Select required name="motivodeimpresion" id="motivodeimpresion" className="form-control" type="text" placeholder="Ingrese el Categoría principal de interés Colombiamoda"
                                                                                            value={this.state.motivo}
                                                                                            onChange={this.selectedChange.bind(this)}
                                                                                        >
                                                                                            <option>Select Seleccione el motivo de reimpresion</option>
                                                                                            {
                                                                                                this.state.listPickerMotivoReempresion.map((option, index) => {
                                                                                                    return <option key={index + 'motivodeimpresion'} value={option.label}>{option.label}</option>
                                                                                                })
                                                                                            }

                                                                                        </Form.Select>
                                                                                    </Form.Group>


                                                                                </div>

                                                                                <div className="col-md-12">

                                                                                    {
                                                                                        this.state.saving
                                                                                            ?
                                                                                            <div className="text-center">
                                                                                                Guardando el motivo de reimpresion y generando escarapela...
                                                                                            </div>
                                                                                            :
                                                                                            <Button variant="success" onClick={this.printMotivo}>
                                                                                                Guardar e Re Imprimir escarapela
                                                                                            </Button>

                                                                                    }

                                                                                </div>

                                                                            </div>


                                                                        </div>






                                                                    </div>
                                                                </div>

                                                                :

                                                                <div className="col-md-12">


                                                                    <div className="row">

                                                                        <div className="col-md-12 mb-4">
                                                                            <ViewAssistant assistant={this.props.assistant}></ViewAssistant>
                                                                        </div>


                                                                        <div className="col-md-12">
                                                                            <div className="row">
                                                                                <div className="col-md-12 mb-5">


                                                                                    <div className="row">
                                                                                        <div className="col-md-6 center-component-mobil">
                                                                                            <b>
                                                                                                Terminos y condiciones:
                                                                                            </b>

                                                                                        </div>
                                                                                        <div className="col-md-6 center-component-mobil">
                                                                                            {this.props.assistant.terms_and_conditions ? 'Si' : 'No'}
                                                                                        </div>
                                                                                    </div>

                                                                                    {
                                                                                        this.props.assistant.terms_and_conditions &&
                                                                                        <div className="row">
                                                                                            <div className="col-md-6 center-component-mobil">
                                                                                                <b>
                                                                                                    Terminos y condiciones (Fecha):
                                                                                                </b>

                                                                                            </div>
                                                                                            <div className="col-md-6 center-component-mobil">
                                                                                                <span>{this.props.assistant.terms_and_conditions_date.getDate() + "-" + (this.props.assistant.terms_and_conditions_date.getMonth() + 1) + "-" + this.props.assistant.terms_and_conditions_date.getFullYear()}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    }


                                                                                    {
                                                                                        this.props.assistant.terms_and_conditions &&
                                                                                        <div className="row">
                                                                                            <div className="col-md-6 center-component-mobil">
                                                                                                <b>
                                                                                                    Terminos y condiciones (Ip):
                                                                                                </b>

                                                                                            </div>
                                                                                            <div className="col-md-6 center-component-mobil">
                                                                                                {this.props.assistant.terms_and_conditions_ip}                                                                                    </div>
                                                                                        </div>
                                                                                    }
                                                                                </div>

                                                                                <div className="col-md-12 mb-5">
                                                                                    <div className="row">
                                                                                        <div className="col-md-6 center-component-mobil">
                                                                                            <b>
                                                                                                Foto
                                                                                            </b>
                                                                                        </div>
                                                                                        <div className="col-md-6 center-component-mobil">
                                                                                            {this.props.assistant.photo ? 'Si' : 'No'}
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="row">
                                                                                        <div className="col-md-8 offset-md-2">
                                                                                            <button className="btn btn-primary btn-sm" onClick={() => this.photoTake()}>
                                                                                                + Ver informacion de la foto
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                                <div className="col-md-12 mb-5">


                                                                                    <div className="row">
                                                                                        <div className="col-md-6 center-component-mobil">
                                                                                            <b>
                                                                                                Escarapela virutal
                                                                                            </b>

                                                                                        </div>
                                                                                        <div className="col-md-6 center-component-mobil">
                                                                                            {this.props.assistant.badge_virtual ? 'Si' : 'No'}
                                                                                        </div>
                                                                                    </div>


                                                                                    {
                                                                                        this.props.assistant.badge_virtual &&
                                                                                        <div className="row">
                                                                                            <div className="col-md-6 center-component-mobil">
                                                                                                <b>
                                                                                                    Fecha Escarapela virtual
                                                                                                </b>

                                                                                            </div>
                                                                                            <div className="col-md-6 center-component-mobil">
                                                                                                <span>{this.props.assistant.badge_virtual_date.getDate() + "-" + (this.props.assistant.badge_virtual_date.getMonth() + 1) + "-" + this.props.assistant.badge_virtual_date.getFullYear()}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    }


                                                                                    <div className="text-center mt-2">
                                                                                        {
                                                                                            this.props.assistant.badge_virtual &&
                                                                                            <div className="row">
                                                                                                <div className="col-md-8 offset-md-2">
                                                                                                    <button className="btn btn-primary btn-sm" onClick={() => this.badgeHistory()}>
                                                                                                        + Ver historial de escarapela virtual
                                                                                                    </button>
                                                                                                </div>
                                                                                            </div>

                                                                                        }
                                                                                    </div>


                                                                                </div>


                                                                            </div>

                                                                        </div>


                                                                        <div className="col-md-12 text-center mb-3">
                                                                            <h5>
                                                                                ¿Esta seguro desea imprimir el participante?
                                                                            </h5>
                                                                        </div>

                                                                        {
                                                                            this.state.saving
                                                                                ?
                                                                                <div className="col-md-12 text-center">
                                                                                    Imprimiendo y generando escarapela...
                                                                                </div>
                                                                                :
                                                                                <div className="row">
                                                                                    <div className="col-md-6">
                                                                                        <Button variant="success" className="m-2" onClick={this.print}>
                                                                                            Si
                                                                                        </Button>
                                                                                    </div>

                                                                                    <div className="col-md-6">
                                                                                        <Button variant="danger" className="m-2" onClick={this.handleClose}>
                                                                                            No
                                                                                        </Button>
                                                                                    </div>

                                                                                </div>

                                                                        }


                                                                    </div>

                                                                </div>


                                                        }



                                                    </div>
                                            }


                                        </div>

                                        :


                                        <div className="row">

                                            <div className="col-md-12 mb-4">
                                                <ViewAssistant assistant={this.props.assistant}></ViewAssistant>
                                            </div>

                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-12 mb-5">


                                                        <div className="row">
                                                            <div className="col-md-6 center-component-mobil">
                                                                <b>
                                                                    Terminos y condiciones:
                                                                </b>

                                                            </div>
                                                            <div className="col-md-6 center-component-mobil">
                                                                {this.props.assistant.terms_and_conditions ? 'Si' : 'No'}
                                                            </div>
                                                        </div>

                                                        {
                                                            this.props.assistant.terms_and_conditions &&
                                                            <div className="row">
                                                                <div className="col-md-6 center-component-mobil">
                                                                    <b>
                                                                        Terminos y condiciones (Fecha):
                                                                    </b>

                                                                </div>
                                                                <div className="col-md-6 center-component-mobil">
                                                                    <span>{this.props.assistant.terms_and_conditions_date.getDate() + "-" + (this.props.assistant.terms_and_conditions_date.getMonth() + 1) + "-" + this.props.assistant.terms_and_conditions_date.getFullYear()}</span>
                                                                </div>
                                                            </div>
                                                        }


                                                        {
                                                            this.props.assistant.terms_and_conditions &&
                                                            <div className="row">
                                                                <div className="col-md-6 center-component-mobil">
                                                                    <b>
                                                                        Terminos y condiciones (Ip):
                                                                    </b>

                                                                </div>
                                                                <div className="col-md-6 center-component-mobil">
                                                                    {this.props.assistant.terms_and_conditions_ip}                                                                                    </div>
                                                            </div>
                                                        }
                                                    </div>

                                                    <div className="col-md-12 mb-5">
                                                        <div className="row">
                                                            <div className="col-md-6 center-component-mobil">
                                                                <b>
                                                                    Foto
                                                                </b>
                                                            </div>
                                                            <div className="col-md-6 center-component-mobil">
                                                                {this.props.assistant.photo ? 'Si' : 'No'}
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-md-8 offset-md-2">
                                                                <button className="btn btn-primary btn-sm" onClick={() => this.photoTake()}>
                                                                    + Ver informacion de la foto
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="col-md-12 mb-5">


                                                        <div className="row">
                                                            <div className="col-md-6 center-component-mobil">
                                                                <b>
                                                                    Escarapela virutal
                                                                </b>

                                                            </div>
                                                            <div className="col-md-6 center-component-mobil">
                                                                {this.props.assistant.badge_virtual ? 'Si' : 'No'}
                                                            </div>
                                                        </div>


                                                        {
                                                            this.props.assistant.badge_virtual &&
                                                            <div className="row">
                                                                <div className="col-md-6 center-component-mobil">
                                                                    <b>
                                                                        Fecha Escarapela virtual
                                                                    </b>

                                                                </div>
                                                                <div className="col-md-6 center-component-mobil">
                                                                    <span>{this.props.assistant.badge_virtual_date.getDate() + "-" + (this.props.assistant.badge_virtual_date.getMonth() + 1) + "-" + this.props.assistant.badge_virtual_date.getFullYear()}</span>
                                                                </div>
                                                            </div>
                                                        }


                                                        <div className="text-center mt-2">
                                                            {
                                                                this.props.assistant.badge_virtual &&
                                                                <div className="row">
                                                                    <div className="col-md-8 offset-md-2">
                                                                        <button className="btn btn-primary btn-sm" onClick={() => this.badgeHistory()}>
                                                                            + Ver historial de escarapela virtual
                                                                        </button>
                                                                    </div>
                                                                </div>

                                                            }
                                                        </div>


                                                    </div>


                                                </div>

                                            </div>




                                            <div className="col-md-12 text-center mb-3">
                                                <h5>
                                                    La categoria a la que pertenece el participante no se puede imprimir por este medio.
                                                </h5>

                                            </div>

                                            <div className="col-md-6 offset-md-3">
                                                <Button variant="danger" className="m-2" onClick={this.handleClose}>
                                                    Cerrar
                                                </Button>
                                            </div>


                                        </div>



                                }

                            </div>



                    }






                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={this.handleClose}>
                        Save Changes
                    </Button>
                </Modal.Footer> */}
            </Modal>
        )
    }

}