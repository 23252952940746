import React, { Component } from "react";
import { Modal, Button, Form } from 'react-bootstrap';
import Swal from "sweetalert2";
import { registerQuotaErrorCallback } from "workbox-core";

import _service from '../../service/service';

import FieldAssistant from './field.assistant';
import ViewAssistant from './view.assistant';
import auth from '../../service/auth';

export default class ModalAssistant extends Component {

    constructor(props) {
        super(props);
        this.state = {
            edit: false,
            countryName: '',
            phoneNumber: '',
            loadingView: true,
            assistant: {
                tel2: this.props.assistant.tel2,
                countryname: this.props.assistant.countryname,
            },
            errors: {},
            countries: [],
            saving: false
        }
    }

    componentDidMount() {
        this.getcountries();
    }

    handleChange = (props, event) => {
        const { value, name } = event.target;
        if (name === props) {
            if (!value) {
                let datAerror = { errors: { ...this.state.errors }, assistant: { ...this.state.assistant } };
                datAerror.errors[props] = true;
                datAerror.assistant[props] = "";
                this.setState({
                    errors: datAerror.errors,
                    assistant: datAerror.assistant
                });
            } else {
                let datAerror = { errors: { ...this.state.errors }, assistant: { ...this.state.assistant } };
                datAerror.errors[props] = false;
                datAerror.assistant[props] = value;
                this.setState({
                    errors: datAerror.errors,
                    assistant: datAerror.assistant
                });
            }
        }
    }

    getcountries = () => {
        _service.getcountries()
            .then(resp => {
                resp.sort(function (a, b) {
                    if (a.name > b.name) {
                        return 1;
                    }
                    if (a.name < b.name) {
                        return -1;
                    }
                    return 0;
                });

                this.setState({
                    loadingView: false,
                    countries: resp
                })
            }, error => {
                this.setState({
                    loadingView: false,
                    countries: []
                })
            });
    }


    handleClose = ($event) => {
        this.props.closeModal(false);
    }

    resentMessage = () => {
        this.setState({
            saving: true,
            messageLoader: "Enviado mensaje"
        });
        let data = {
            edit: this.state.edit,
            countryName: this.state.assistant.countryname,
            phoneNumber: this.state.assistant.tel2
        }

        _service.saveResentWhatapps(this.props.assistant.id, auth.user.id, data)
            .then(resp => {
                Swal.fire('Información', 'Se envio correctamente el mensaje', 'success')
                this.setState({
                    saving: false,
                    messageLoader: "Se completo la informacion"
                });
                this.props.closeModal(false);

            }).catch(error => {
                this.setState({
                    saving: false,
                    messageLoader: "Error de base de datos"
                });
            })

    }

    render() {


        return (
            <Modal show={this.props.show} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    {
                        this.props.assistant ? <Modal.Title>{this.props.assistant.name} {this.props.assistant.lastName} </Modal.Title>
                            : <div></div>
                    }
                </Modal.Header>
                <Modal.Body>

                    {
                        this.state.loadingView ?
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <div className="alert alert-primary text-center">
                                        Cargando...
                                    </div>
                                </div>
                            </div>
                            :
                            <div className="row">

                                {
                                    this.state.edit ?
                                        <div className="col-md-12">
                                            <div className="row">

                                                <div className="col-md-12 text-center">

                                                    <Form.Group className="mb-3 control-form" controlId="tel2">
                                                        <Form.Label>Celular <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control required name="tel2" id="tel2" className="form-control" type="text" placeholder="Ingrese el Celular"
                                                            value={this.state.assistant["tel2"]}
                                                            onChange={this.handleChange.bind(this, "tel2")}
                                                        />
                                                        {
                                                            this.state.errors["tel2"] ? (<Form.Text className='error-field'>
                                                                * El Celular es requerido
                                                            </Form.Text >) : (<div>
                                                            </div>)
                                                        }
                                                    </Form.Group>
                                                </div>

                                                <div className="col-md-12 text-center">
                                                    <Form.Group className="mb-3 control-form    " controlId="countryname">
                                                        <Form.Label>Pais <span className="text-danger">*</span></Form.Label>
                                                        <Form.Select required name="countryname" id="countryname" className="form-control" type="text" placeholder="Ingrese el Pais"
                                                            value={this.state.assistant["countryname"]}
                                                            onChange={this.handleChange.bind(this, "countryname")}
                                                        >
                                                            <option key={'countriesprincipal'}>Seleccione el pais</option>
                                                            {
                                                                this.state.countries.map((option, index) => {
                                                                    return <option key={index + 'countries'} value={option.name}>{option.name}</option>
                                                                })
                                                            }

                                                        </Form.Select>

                                                        {
                                                            this.state.errors["countryname"] ? (<Form.Text className='error-field'>
                                                                * El Pais es requerido
                                                            </Form.Text >) : (<div>

                                                            </div>)
                                                        }
                                                    </Form.Group>


                                                </div>

                                                <div className="col-md-12 text-center">

                                                    <div className="mt-2 mb-3">
                                                        <a variant="info" type="button" onClick={() => {
                                                            this.setState({
                                                                edit: !this.state.edit
                                                            })
                                                        }}>Cancelar edición</a>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                        :

                                        <div className="col-md-12">
                                            <div className="mt-2">
                                                <b>Celular : </b> {this.state.assistant["tel2"]}
                                            </div>
                                            <div className="mt-2">
                                                <b>Pais :</b>   {this.state.assistant["countryname"]}
                                            </div>

                                            <div className="mt-2 mb-3">
                                                Si deseas editar el numero de celular o el pais click <a variant="info" type="button" onClick={() => {
                                                    this.setState({
                                                        edit: !this.state.edit
                                                    })
                                                }}>Aquí</a>
                                            </div>
                                        </div>
                                }


                                <div className="col-md-12 text-center">

                                    {
                                        !this.state.saving
                                            ?

                                            <Button variant="primary" type="button"
                                                onClick={this.resentMessage}
                                            >
                                                Reenviar mensaje
                                            </Button>
                                            : <div className="text-center">
                                                {this.state.messageLoader}
                                            </div>
                                    }
                                </div>

                            </div>
                    }

                </Modal.Body>
            </Modal >
        )
    }

}