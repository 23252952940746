import React, { Component } from "react";
import { Modal, Button, Form } from 'react-bootstrap';
import Swal from "sweetalert2";
import _service from '../../service/service';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

export default class ModalPhotoAssistant extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            loadingView: true,
            idassistant: '',
            timer: (new Date().getTime()) + "",
            takephoto: false,
            dataUri: '',
            loadingPhoto:false
        };
        this.getInformationPhoto();
    }

    componentDidMount() {

    }

    handleTakePhoto = (dataUri) => {
        // Do stuff with the photo...
        this.setState({
            dataUri: dataUri
        })
        console.log('takePhoto', dataUri);
    }

    savePhoto = () =>{
        if(this.state.loadingPhoto){
            return;
        }

        this.setState({
            loadingPhoto: true
        });
        _service.savePhoto(this.state.idassistant, this.state.dataUri.replace("data:image/png;base64,",""))
        .then(resp=>{
            Swal.fire('Informacion','Se guardo correctamente la imagen', 'success');
            this.setState({
                dataUri: '',
                takephoto: false,
                loadingPhoto: false,
            });
            this.props.closeModal(false);
        })
        .catch(error=>{
            this.setState({
                loadingPhoto: false
            });
        })
    }

    cameraTake() {
        this.setState({
            takephoto: true
        });
    }
    getInformationPhoto() {
        this.setState({
            loadingView: true
        });
        _service.getInformationPhoto(this.props.assistant.id)
            .then(resp => {
                console.log(resp);
                this.setState({
                    idassistant: resp.message,
                    loadingView: false
                })
            })
            .catch(erro => {
                this.setState({
                    loadingView: true
                });
            });
    }


    handleClose = ($event) => {
        this.props.closeModal(false);
    }

    render() {


        return (
            <Modal show={this.props.show} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    {
                        this.props.assistant ? <Modal.Title>{this.props.assistant.name} {this.props.assistant.lastName} </Modal.Title>
                            : <div></div>
                    }
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.takephoto &&
                        <div className="row">

                            <div className="col-md-12">
                                {
                                    this.state.dataUri ?
                                        <div className="row">
                                            <div className="col-md-12">
                                                <img src={this.state.dataUri} width='100%' />
                                            </div>
                                            <div className="col-md-12">
                                                <button className="btn btn-primary btn-sm mt-3"
                                                    onClick={() => this.setState({
                                                        dataUri: ''
                                                    })}
                                                >
                                                    Repetir foto
                                                </button>

                                                <button className="btn btn-primary btn-sm mt-3"
                                                    onClick={() => this.savePhoto()}
                                                >
                                                    { this.state.loadingPhoto ? 'Guardando...' : 'Guardar foto'}
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        <Camera
                                            onTakePhoto={(dataUri) => { this.handleTakePhoto(dataUri); }}
                                            imageType={IMAGE_TYPES.PNG}
                                            isFullscreen={false}
                                            imageCompression={0.80}
                                            idealFacingMode={FACING_MODES.ENVIRONMENT}
                                        />
                                }
                            </div>

                        </div>
                    }

                    {
                        this.state.loadingView &&
                        <div className="row">
                            <div className="col-md-12">
                                <div className="alert alert-info text-center">
                                    ...
                                </div>
                            </div>
                        </div>
                    }

                    {
                        (!this.state.takephoto && !this.state.loadingView) &&
                        <div className="row">
                            <div className="col-md-12">



                                <div className="row">
                                    <div className="col-md-12">
                                        <a target="_new" href={'https://ev.aplicacionesincontacto.com/#/photo/' + this.state.idassistant}>
                                            {'https://ev.aplicacionesincontacto.com/#/photo/' + this.state.idassistant}
                                        </a>
                                    </div>

                                    <div className="col-md-12">
                                        <img src={'https://aplicacionesincontacto.com/formsin/Qr?qrtext=' + encodeURIComponent('https://ev.aplicacionesincontacto.com/#/photo/' + this.state.idassistant)}
                                            style={{ width: '100%', borderRadius: '5px' }} />
                                    </div>

                                </div>

                                {
                                    this.props.assistant.photo ?
                                        <div className="row">
                                            <div className="col-md-12">
                                                <img src={'https://photosinexmoda.s3.amazonaws.com/' + this.state.idassistant + '.png' + '?timer=' + this.state.timer}
                                                    style={{ width: '100%', borderRadius: '5px' }} />
                                            </div>



                                        </div>

                                        :
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="alert alert-warning">
                                                    Aún no tiene foto
                                                </div>
                                            </div>
                                        </div>


                                }

                                <div className="row mt-3">
                                    <div className="col-md-12">
                                        <button className="btn btn-primary btn-sm"
                                            onClick={() => this.cameraTake()}
                                        >
                                            Tomar foto
                                        </button>
                                    </div>
                                </div>


                            </div>

                        </div>

                    }







                </Modal.Body>

            </Modal>
        )
    }

}