import React, { Component } from "react";
import { Modal, Button, Form } from 'react-bootstrap';
import Swal from "sweetalert2";
import _service from '../../service/service';
export default class ModalBadgeHistoryAssistant extends Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            loadingView: true
        };
        this.getHistoryBadge();
    }

    componentDidMount() {

    }

    getHistoryBadge = () => {
        this.setState({
            loadingView: true
        });
        _service.getHistoryBadge(this.props.assistant.id)
            .then(resp => {
                this.setState({
                    items: resp,
                    loadingView: false
                })
            })
            .catch(erro => {
                this.setState({
                    loadingView: true
                });
            });
    }

    handleClose = ($event) => {
        this.props.closeModal(false);
    }

    render() {


        return (
            <Modal show={this.props.show} onHide={this.handleClose}>
                <Modal.Header closeButton>
                    {
                        this.props.assistant ? <Modal.Title>{this.props.assistant.name} {this.props.assistant.lastName} </Modal.Title>
                            : <div></div>
                    }
                </Modal.Header>
                <Modal.Body>
                    {
                        this.state.loadingView  &&
                        <div className="row">
                            <div className="col-md-12">
                                <div className="alert alert-info text-center">
                                    ...
                                </div>
                            </div>
                        </div>
                    }

                    {
                        this.state.items.map(item => {
                            if (item.badge_virtual_date) {
                                item.badge_virtual_date = new Date(item.badge_virtual_date);
                            }

                            if (item.meta_data) {
                                item.meta_data2 = JSON.parse(item.meta_data);
                            }

                            return <div className="card mb-2">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <b>  Fecha de generación</b>
                                        </div>

                                        <div className="col-md-12 mb-2">
                                            <span>{item.badge_virtual_date.getDate() + "-" + (item.badge_virtual_date.getMonth() + 1) + "-" + item.badge_virtual_date.getFullYear()}</span>
                                        </div>

                                        <div className="col-md-12">
                                            <b> IP</b>
                                        </div>
                                        <div className="col-md-12 mb-2">
                                            {item.badge_virtual_date_ip}
                                        </div>

                                        <div className="col-md-12">
                                            <b> Email</b>
                                        </div>

                                        <div className="col-md-12 mb-2">
                                            {item.email}
                                        </div>

                                        <div className="col-md-12">
                                            <b> Sistema operativo</b>
                                        </div>

                                        <div className="col-md-12 mb-2">
                                            {item.meta_data2.os}
                                        </div>


                                        <div className="col-md-12">
                                            <b> Navegador</b>
                                        </div>

                                        <div className="col-md-12">
                                            {item.meta_data2.browser}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }


                </Modal.Body>

            </Modal>
        )
    }

}