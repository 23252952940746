import { Component } from "react";
import {
    Route,
    Routes
} from 'react-router-dom';
import Login from "./pages/login/Login";
import Tabs from "./pages/tabs/tabs";
import auth from "./service/auth";

export default class Manager extends Component {

    constructor(props) {
        super(props);
        this.init();
    }

    init() {
    }

    render() {

        return (
            <Routes>
                <Route path="/" element={
                    <auth.RequireLoginValidation>
                    </auth.RequireLoginValidation>
                } />
                <Route path="/login" element={
                    <auth.RequireLogin>
                        <Login navigate={this.props.navigate} />
                    </auth.RequireLogin>
                }
                />
                <Route path="/tabs" element={
                    <auth.RequireAuth>
                        <Tabs navigate={this.props.navigate} />
                    </auth.RequireAuth>
                }>

                    <Route path="qr" element={
                        <auth.redirectQR />
                    } />

                    <Route path="assistant" element={
                        <auth.assistantEvent />
                    } />



                    <Route path="config" element={
                        <auth.ConfigEventFuntion />
                    } />

                    <Route path="controlaccess" element={
                        <auth.redirectControlAccess />
                    } />




                </Route>
            </Routes>
        )
    }

}