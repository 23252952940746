import React from 'react';
import './App.css';
import {
  useNavigate,
} from 'react-router-dom';
import Manager from './manager';

function App() {
  const navigate = useNavigate();
  return (
    <div className="App">
      <Manager navigate={navigate} />
    </div>
  );
}

export default App;
