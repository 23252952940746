import React from "react";
import _service from '../../service/service';
import QrReader from 'react-qr-reader';
import Swal from "sweetalert2";
import { Form } from 'react-bootstrap';

export default class ControlAccess extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            typeView: 2,
            zones: [],
            loadingView: true,
            data: {
                zoneselected: null
            },
            message: '',
            searching: false
        };
        this.getZones();
    }

    handleScan = (data) => {
        console.log(data);
        if (data) {
            this.setState({ valueEnter: data, noLectura: true, typeView: 2 });
            this.validateAccess(data);
        }
    }

    getZones = () => {
        _service.getZones()
            .then(resp => {
                this.setState({
                    zones: resp,
                    loadingView: false
                });
                this.initFocus();
            })
            .catch(error => {
                this.setState({
                    loadingView: false
                })
            });
    }


    clickType = (typeView) => {

        if (!this.state.data.zoneselected && typeView == 1) {
            Swal.fire('Información', 'Debe seleccionar alguna zona para poder realizar la accion', 'info');
            return;
        }



        this.setState({
            typeView: typeView
        })
    }

    handleError = (err) => {
        console.error(err)
    }


    changeKeyBoard = () => {
        this.setState({
            typeKeyKeyboard: (this.state.typeKeyKeyboard === 1 ? 2 : 1)
        })
    }

    onKeyUp = (event) => {
        if (event.charCode === 13) {
            this.validateAccess();
        }
    }

    validateAccess = async (data = "") => {
        let value = this.state.valueEnter;
        if (data) {
            value = data;
        }

        if (!value) {
            Swal.fire('Información', 'Debe ungresar un valor para continuar', 'info');
            return;
        }


        if (!this.state.data.zoneselected) {
            Swal.fire('Información', 'Debe seleccionar alguna zona para poder realizar la accion', 'info');
            return;
        }

        this.setState({
            searching: true
        });

        let isBadge = false;
        let valueSearch = value;
        if ((value.indexOf("/")) >= 0) {
            valueSearch = value.split("/")[value.length - 1];
        }

        if (value.indexOf("&&&") >= 0) {

            valueSearch = value.split("&&&")[0];

            if (value.split("&&&")[1] + "".toLowerCase() == "v" || value.split("&&&")[1] + "" == "v" || value.split("&&&")[1] + "" == "V") {
                isBadge = false;
            }

            if (value.split("&&&")[1] + "".toLowerCase() == "p"  || value.split("&&&")[1] + "" == "P" || value.split("&&&")[1] + "" == "p") {
                isBadge = true;
            }
        }

        if ((value.indexOf("|")) >= 0) {
            valueSearch = value.split("|")[0];
        }

        _service.getAccess(this.state.data.zoneselected, valueSearch, isBadge)
            .then(resp => {
                if (resp.error && resp.error == 1) {
                    this.messageScreet(resp);
                    return;
                }

                this.messageScreet(resp);
                this.initFocus();
            })
            .catch(error => {
                this.setState({
                    message: "<div align='center'><h3 class='text-danger'>Ocurrio un error en el servidor porfavor, comuniquese con el administrador</h3></div>",
                    searching: false
                })
            })
    }


    messageScreet = (resp) => {
        let message = "<div class='text-center'>";
        if (resp.name) {
            message += `<h2 class='text-info'>${resp.name.toUpperCase()}</h2>`;
        }

        if (resp.lastName) {
            message += `<h2 class='text-info'>${resp.lastName.toUpperCase()}</h2>`;
        }

        if (resp.company) {
            message += `<h4 class='text-info'>${resp.company.toUpperCase()}</h4>`;
        }
        if (resp.category) {
            message += `<h3 class='text-success'>${resp.category.toUpperCase()}</h3>`;
        }

        if (resp.observation) {
            message += `<h3 class='text-warning'><b>${resp.observation.toUpperCase()}</b></h3>`;
        }


        if (resp.message) {
            message += ` <hr/><div align='center'><h3 class='text-danger'>${resp.message}</h3></div>`;
        }
        message += "</div>";
        this.setState({
            message: message,
            searching: false,
            valueEnter: ''
        })

    }


    changeValurIdentification = (event) => {
        const { value } = event.target;
        if (value) {
            this.setState({
                valueEnter: value
            });
        } else {
            this.setState({
                valueEnter: ""
            });
        }
    }


    changeValurIdentification = (event) => {
        const { value } = event.target;
        if (value) {
            this.setState({
                valueEnter: value
            });
        } else {
            this.setState({
                valueEnter: ""
            });
        }
    }



    choiceButton = () => {
        if (this.state.facingMode === "environment") {
            this.setState({
                facingMode: "user"
            })
        } else {
            this.setState({
                facingMode: "environment"
            })
        }
    }


    handleChange = (props, event) => {
        const { value, name } = event.target;
        if (name === props) {
            if (!value) {
                let datAerror = { errors: { ...this.state.errors }, assistant: { ...this.state.data } };
                datAerror.errors[props] = true;
                datAerror.data[props] = "";
                this.setState({
                    errors: datAerror.errors,
                    data: datAerror.data
                });
            } else {
                let datAerror = { errors: { ...this.state.errors }, data: { ...this.state.data } };
                datAerror.errors[props] = false;
                datAerror.data[props] = value;
                this.setState({
                    errors: datAerror.errors,
                    data: datAerror.data
                });
            }
        }
    }

    initFocus() {
        setTimeout(() => {
            let fieldFocus = document.getElementById('valueEnter');
            if (fieldFocus) {
                fieldFocus.focus();
            }
        }, 200);
    }



    render() {
        const previewStyle = {
            position: "fixed",
            left: 0,
            top: "70px",
            bottom: 0,
            right: 0,
            zIndex: 100,
            backgroundColor:"#fff"
        }

        const previewStyleButtion = {
            position: "fixed",
            top: "70px",
            right: 0,
            left: 0,
            zIndex: 300
        }

        const previewStyleButtion2 = {
            position: "fixed",
            bottom: "70px",
            right: 0,
            left: 0,
            zIndex: 300
        }



        return (
            <div className="row">
                {
                    this.state.loadingView ?

                        <div className="col-md-12">
                            <div className="text-center alert alert-info">

                            </div>
                        </div>

                        :
                        <div className="row">

                            <div className="col-md-12 text-center">
                                <h4 style={{ fontWeight: 300 }}> Control de acceso</h4>
                            </div>

                            <div className="col-md-12">
                                <Form.Group className="mb-3 control-form" controlId="zoneselected">
                                    <Form.Label>Seleccione la zona<span className="text-danger">*</span></Form.Label>
                                    <Form.Select required name="zoneselected" id="zoneselected" className="form-control" type="text" placeholder="Seleccione la zona"
                                        value={this.state.data["zoneselected"]}
                                        onChange={this.handleChange.bind(this, "zoneselected")}
                                    >
                                        <option></option>
                                        {
                                            this.state.zones.map((item, index) => {
                                                return <option key={index + 'zone'} value={item.id}  >{item.name}</option>;
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </div>


                            {
                                this.state.typeView != 1
                                &&
                                <div className="col-md-12 text-center">
                                    <div className="row">
                                        <div className='col-md-4 offset-md-2 pt-2 pb-2'>
                                            <button type='button' className='btn btn-danger text-white' onClick={() => this.clickType(1)} >Leer QR</button>
                                        </div>
                                        <div className='col-md-4 pt-2 pb-2'>
                                            <button type='button' className='btn btn-danger text-white' onClick={() => this.clickType(2)}>Escribit ID</button>
                                        </div>
                                    </div>
                                </div>
                            }


                            {
                                this.state.typeView === 2 ?
                                    (<><div className='col-md-8 offset-md-2'>
                                        <div className="input-group has-validation">
                                            <input type={this.state.typeKeyKeyboard === 1 ? 'number' : "text"}
                                                onKeyPress={this.onKeyUp}
                                                onChange={this.changeValurIdentification}
                                                value={this.state.valueEnter}
                                                id="focusField"
                                                className='form-control form-control-lg form-control-text' />
                                            <span className="input-group-text form-control-border-group" id="inputGroupPrepend"
                                                onClick={this.changeKeyBoard}
                                            >
                                                {this.state.typeKeyKeyboard === 1 ? '123' : "ABC"}
                                            </span>
                                        </div>
                                    </div>
                                        {
                                            this.state.searching ?

                                                <div className='text-center mt-5 alert alert-info' >
                                                    Realizando busqueda...
                                                </div>
                                                :

                                                <div className='col-md-8 offset-md-2 mt-2'>
                                                    <button type='button' className='btn btn-danger text-white' onClick={() => this.validateAccess()}  >Buscar</button>
                                                </div>
                                        }
                                    </>

                                    )
                                    :
                                    (<></>)
                            }


                            {
                                this.state.typeView === 1 ?
                                    (
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="center-component">
                                                        <QrReader
                                                            delay={this.state.delay}
                                                            style={previewStyle}
                                                            onError={this.handleError}
                                                            onScan={this.handleScan}
                                                            facingMode={this.state.facingMode}
                                                            legacyMode={this.state.legacyMode}
                                                            ref="qrReader"
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-md-2 offset-md-5 mt-5 pt-5">
                                                    <button type="button" className='btn btn-danger' style={previewStyleButtion} onClick={this.choiceButton} >
                                                        <i className="fa-solid fa-camera-rotate text-white" style={{ fontSize: '18px' }}></i>
                                                    </button>
                                                </div>
                                                <div className="col-md-2 offset-md-5 mt-5 pt-5">
                                                    <button type="button" className='btn btn-danger' style={previewStyleButtion2} onClick={() => this.clickType(2)} >
                                                        Cancelar
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    :
                                    (<></>)




                            }

                            {
                                this.state.message
                                &&

                                <div className="col-md-12 mt-2">
                                    <div className="text-center" dangerouslySetInnerHTML={{ __html: this.state.message }}>
                                    </div>
                                </div>


                            }

                        </div>
                }

            </div>

        )
    }
}